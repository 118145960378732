'use client'

import './globals.css'

import { GoogleTagManager } from '@next/third-parties/google'
import moment from 'moment'
import { Open_Sans } from 'next/font/google'
import Link from 'next/link'
import Script from 'next/script'
import { ReactNode, useEffect } from 'react'
import { Toaster } from 'react-hot-toast'

import ApolloWrapper from '@/components/apollo.wrapper'

const open = Open_Sans({ subsets: ['latin'] })

export default function RootLayout({ children }: { children: ReactNode }) {
  useEffect(() => {
    const now = moment()
    const end = moment().add(7, 'days')

    const data = window.localStorage.getItem('visitante_premiado')

    if (data === null || now > moment(JSON.parse(data))) {
      window.localStorage.setItem('visitante_premiado', JSON.stringify(end))
      let item: any = document.getElementById('modal_visitante_premiado')
      item!.showModal()
    }
  }, [])

  return (
    <html lang="pt-BR">
      <head>
        <link
          rel="icon"
          type="image/x-icon"
          href="/assets/favicon.ico"
          sizes="any"
        />
        <meta
          name="adopt-website-id"
          content="467b5d37-cad2-4720-ac45-50e569fd8909"
        />
        <Script
          src="//tag.goadopt.io/injector.js?website_code=467b5d37-cad2-4720-ac45-50e569fd8909"
          className="adopt-injector"
          strategy="lazyOnload"
        />
      </head>
      <body className={`${open.className}`}>
        <ApolloWrapper>{children}</ApolloWrapper>
        <dialog id="modal_visitante_premiado" className="modal">
          <div className="modal-box border-0 p-0">
            <div className="bg-footer py-4 text-white">
              <form method="dialog">
                <button className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2 focus-visible:outline-none">
                  ✕
                </button>
              </form>
              <p className="mt-4 text-center text-lg font-bold md:mt-0">
                Visitante Premiado - Bolsa Desconto
              </p>
            </div>
            <div className="bg-white p-6">
              <p className="pb-4 text-black">
                <b>Parabéns!</b> Você Ganhou 1 Bolsa Desconto para iniciar sua
                Graduação ou Pós-graduação EAD na Faculdade Metropolitana - Nota
                Máxima no MEC!
              </p>
              <div className="grid grid-cols-1 justify-center -space-y-2">
                <Link
                  href="https://www.estudesemfronteiras.com/novo/visitante-premiado-graduacao"
                  className="md:w-100 btn btn-sm mx-auto flex min-h-[55px] w-[340px] border-0 bg-verdeBtn p-2 capitalize leading-3 text-white hover:scale-105 hover:bg-verdeBtn hover:text-white"
                  target="new"
                >
                  Quero Bolsa para <b>Graduação EAD</b>
                </Link>
                <br />
                <Link
                  href="https://www.estudesemfronteiras.com/novo/visitante-premiado"
                  className="md:w-100 btn btn-sm mx-auto flex min-h-[55px] w-[340px] border-0 bg-verdeBtn p-2 capitalize leading-3 text-white hover:scale-105 hover:bg-verdeBtn hover:text-white"
                  target="new"
                >
                  Quero Bolsa para <b>Pós-Graduação EAD</b>
                </Link>
                <br />
                <Link
                  href="https://www.estudesemfronteiras.com/novo/visitante-premiado-graduacao-presencial"
                  className="md:w-100 btn btn-sm mx-auto flex min-h-[55px] w-[340px] border-0 bg-verdeBtn p-2 capitalize leading-3 text-white hover:scale-105 hover:bg-verdeBtn hover:text-white"
                  target="new"
                >
                  Quero Bolsa para <b>Graduação Presencial - Administração</b>
                </Link>
                <br />
              </div>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
        {/* <Script
          src="//code.jivosite.com/widget/BUcq7WLKqW"
          async
          strategy="lazyOnload"
        /> */}
        <Toaster />
      </body>
      <GoogleTagManager gtmId="GTM-P33ZJF6V" />
    </html>
  )
}
